<footer class="px-5 py-3">
  <div class="pre-footer">
    <div class="footer-graphic"></div>
    <!-- <div class="pre-footer-logo"></div> -->
  </div>

  <div class="text-center d-flex align-items-center justify-content-between">
    <div class="footer-logo"></div>
    <p class="mb-0 fw-bold">
      © 2024 <a href="https://oliverwight-eame.com">Oliver Wight EAME LLP</a>. All rights reserved.
    </p>
    <a href="https://www.designone.co.uk">
      <div class="powered-by-logo"></div>
    </a>
  </div>
</footer>
