<p>
</p>

<form [formGroup]="userForm" (ngSubmit)="onSubmit()" class="container" *ngIf="selectedUser >= 0">
    <div class="row">
        <div class="col-6">
            <label for="name">Name:</label>
            <input type="text" id="name" formControlName="name">
        </div>
        <div class="col-6">
            <label for="role">Function:</label>
            <input type="text" id="role" formControlName="role">
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <label for="email">Email:</label>
            <input type="email" id="email" formControlName="email">
        </div>
  
        <div class="col-6">
            <button type="submit" [disabled]="userForm.invalid">Save</button>
        </div>
    </div>
</form>