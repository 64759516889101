import { Component, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AssessmentsService } from 'src/app/assessments.service';


@Component({
  selector: 'app-edit-region',
  templateUrl: './edit-region.component.html',
  styleUrls: ['./edit-region.component.css']
})
export class EditRegionComponent {
  regionForm: FormGroup;

  @Input() regions: object[] = [];
  @Input() selectedRegion: number = -1;

  constructor(private assessments: AssessmentsService) {}

  ngOnInit() {
    this.regionForm = new FormGroup({
      label: new FormControl('', Validators.required),
    });
    this.updateRegionForm();
  }

  ngOnChanges(changes) {
    this.updateRegionForm();
  }

  updateRegionForm() {
    if(this.regionForm) {
      if(this.selectedRegion >= 0) {
        var region = this.regions[this.selectedRegion];
        this.regionForm.controls.label.setValue(region['label']);
      }
      else {
        this.regionForm.controls.label.setValue('');
      }
    }
  }
  
  onSubmit() {
    const id = this.selectedRegion >= 0 ? this.regions[this.selectedRegion]['id'] : 0;
    this.assessments.updateRegion$(id, {
        'label': this.regionForm.controls.label.value,
    }).subscribe({
      next: (res) => {
        if('id' in res) {
          if(this.selectedRegion >= 0) {
            this.regions[this.selectedRegion] = res;
          }
          else {
            this.regions.push(res);
          }
          // this.regionForm.reset();
        }
      },
    });
  }
}
