import { Component, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AssessmentsService } from 'src/app/assessments.service';


@Component({
  selector: 'app-edit-market',
  templateUrl: './edit-market.component.html',
  styleUrls: ['./edit-market.component.css']
})
export class EditMarketComponent {
  marketForm: FormGroup;

  @Input() markets: object[] = [];
  @Input() regions: object[] = [];
  @Input() selectedMarket: number = -1;

  constructor(private assessments: AssessmentsService) {}

  ngOnInit() {
    this.marketForm = new FormGroup({
      label: new FormControl('', Validators.required),
      region: new FormControl('')
    });
    this.updateMarketForm();
  }

  ngOnChanges(changes) {
    this.updateMarketForm();
  }

  updateMarketForm() {
    if(this.marketForm) {
      if(this.selectedMarket >= 0) {
        var market = this.markets[this.selectedMarket];
        this.marketForm.controls.label.setValue(market['label']);
        this.marketForm.controls.region.setValue(market['region_id']);
      }
      else {
        this.marketForm.controls.label.setValue('');
        this.marketForm.controls.region.setValue('');
      }
    }
  }
  
  onSubmit() {
    const id = this.selectedMarket >= 0 ? this.markets[this.selectedMarket]['id'] : 0;
    this.assessments.updateMarket$(id, {
        'label': this.marketForm.controls.label.value,
        'region_id': parseInt(this.marketForm.controls.region.value),
    }).subscribe({
      next: (res) => {
        if('id' in res) {
          if(this.selectedMarket >= 0) {
            this.markets[this.selectedMarket] = res;
          }
          else {
            this.markets.push(res);
          }
        }
      },
    });
  }
}
