import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AssessmentsService } from 'src/app/assessments.service';

@Component({
  selector: 'app-assessment-overview',
  templateUrl: './assessment-overview.component.html',
  styleUrls: ['./assessment-overview.component.css']
})
export class AssessmentOverviewComponent {
  @Input() data: object;
  context: object;
  @Input() module_id: number;

  @Input() chart_data: object = {};
  @Input() chart_series: object[] = [];
  @Input() chart_selected: string[] = [];

  @Output() module:EventEmitter<number> = new EventEmitter<number>();
  @Output() series:EventEmitter<object> = new EventEmitter<object>();

  constructor(private assessments: AssessmentsService) {
    assessments.sessionContext$.subscribe({
      next: (context) => {
        this.context = context;
      }}
    );
  }

  setModule(module_id) {
    this.module.emit(module_id);
  }

  chartSeries(event) {
    this.series.emit({
      'action': event.target.checked ? 'add' : 'remove',
      'assessment_id': event.target.value.toString()
    })
  }
}
