import { Component, Input, Output, EventEmitter } from '@angular/core';
import { AssessmentsService } from 'src/app/assessments.service';


@Component({
  selector: 'app-question',
  templateUrl: './question.component.html',
  styleUrls: ['./question.component.css']
})
export class QuestionComponent {
  @Input() question: object;
  @Input() context: object;
  @Input() all_answered = false;
  @Input() busy = false;

  @Output() answer: EventEmitter<number> = new EventEmitter<number>();
  @Output() note: EventEmitter<string> = new EventEmitter<string>();

  attachmentFormOpen = false;
  textAttachment = { id: 0, content: '' };
  newText = '';

  constructor(
    private assessments: AssessmentsService,
  ) {}

  ngOnChanges(changes) {
    // console.log(changes);
    if('question' in changes && changes.question.currentValue) {
      const q = changes.question.currentValue.q;
      if(q.text_attachments.length) {
        this.textAttachment = q.text_attachments[0];
        this.attachmentFormOpen = true;
      }
      else {
        this.textAttachment = { id: 0, content: '' } // blank form for new
      }
      this.newText = '';
    }
  }

  navNextQuestion() {
    this.assessments.pushAction('q_nav', {'q_index': this.question['i']+1});
  }

  navPrevQuestion() {
    this.assessments.pushAction('q_nav', {'q_index': this.question['i']-1});
  }

  setAnswer(id) {
    this.answer.emit(id);
  }

  setAttachmentText(id, event) {
    this.newText = event.target.value;
    // this.textAttachment['content'] = event.target.value;
  }

  saveAttachment() {
    const text = this.newText;
    if(text) {
      this.textAttachment['content'] = text;
      this.note.emit(text);
    }
  }

  resetQuestion() {
    if(confirm(
      "Resetting this question will clear any notes and answers currently added. " +
      "Are you sure that you want to reset the question?"
    )) {
      if(this.question['q']['current_answer']) {
        this.answer.emit(null);
      }
      if(this.textAttachment['content']) {
        this.textAttachment['content'] = '';
        this.note.emit('');
      }
    }
  }

  setCompleted() {
    if(confirm(
      "Once completed, you will no longer be able to make changes to your answers. " +
      "Are you sure that you are ready to complete this assessment?"
    )) {
      this.assessments.pushAction('complete');
    }
  }
}
