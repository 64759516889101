import { Component, Input } from '@angular/core';
import { Chart } from 'chart.js/auto';

@Component({
  selector: 'app-assessment-radar',
  templateUrl: './assessment-radar.component.html',
  styleUrls: ['./assessment-radar.component.css']
})
export class AssessmentRadarComponent {
  public chartjs: any;

  @Input() chart_data: object = {};
  @Input() chart_series: object[] = [];
  @Input() chart_selected: string[] = [];

  ngOnChanges(changes) {
    if('chart_selected' in changes && this.chart_data) {
      this.renderChart();
    }
    else {
      this.blankChart();
    }
  }

  blankChart() {
    if(this.chartjs) {
      this.chartjs.destroy();
    }

    this.chartjs = new Chart("radar-chart", {
      type: 'radar',
      data: { labels: [], datasets: [] },
      options: {
        responsive: true,
        scales: { r: { max: 4, min: 0, ticks: { stepSize: 1 }, grid: { circular: true } } },
        plugins: { legend: { display: false } }
      }
    });
  }

  renderChart() {
    if(this.chartjs) {
      this.chartjs.destroy();
    }
    var labels = [];
    var datasets = [];

    const style = getComputedStyle(document.body);

    for(const [i, series] of this.chart_series.entries()) {
      if(this.chart_selected.indexOf(series['id']) >= 0 ) {
        const data = this.chart_data[series['id']];
        const background_colour = style.getPropertyValue(`--dataset${i+1}-background`);
        const border_colour = style.getPropertyValue(`--dataset${i+1}-border`);

        if(labels.length == 0) {
          labels = data.map((x) => { return x['label'] });
        }
        if(data) {
          datasets.push({
            'data': data.map((x) => { return x['score'] }),
            'fill': true,
            'backgroundColor': background_colour,
            'borderColor': border_colour,
            'pointBackgroundColor': border_colour,
            'pointBorderColor': '#fff',
            'pointHoverBackgroundColor': '#fff',
            'pointHoverBorderColor': border_colour
          });
        }
      }
    }
    this.chartjs = new Chart("radar-chart", {
      type: 'radar',
      data: {
        'labels': labels,
        'datasets': datasets,
      },
      options: {
        responsive: true,
        scales: {
          r: {
            max: 4, min: 0,
            ticks: { stepSize: 1 },
            grid: { circular: true },
          }
        },
        plugins: {
          legend: { display: false }
        }
      },
    });
  }
}
