<div class="mb-3 module1">
  <div class="title-area">
    <div class="titles">
      <h1>{{ context['title'] }}</h1>
      <h2>{{ context['sub_title'] }}</h2>
    </div>
    <div class="aspect" *ngIf="context['assessment']">
      {{ context['assessment']['aspect'] }}
    </div>
  </div>
</div>