<div class="d-flex mt-3">
  <div class="col-6">
    <app-assessment-radar [chart_data]="chart_data" [chart_series]="chart_series" [chart_selected]="chart_selected" />
  </div>
  <div class="col-6 ps-5">
    <h2>Assessment Overview</h2>
    <select class="module" *ngIf="data" (change)="setModule($event.target.value)">
      <option *ngFor="let m of data['modules']"
        [value]="m.id" [selected]="m.id == module_id"
      >
        {{ m.label }}
      </option>
    </select>

    <p class="graph-info my-3 mt-5">
      Graph shows comparisons between previous assessments from selection below:
    </p>

    <div class="chart-series" *ngIf="chart_series">

      <div class="my-2" *ngFor="let series of chart_series; let i = index">
        <input type="checkbox" [id]="'series' + i" [class]="'series' + (i+1)" [value]="series.id"
          [checked]="chart_selected.includes(series.id.toString())" (change)="chartSeries($event)"
        />
        <label [for]="'series' + i" [class]="'series' + (i+1)">
          #{{ series['id'] }} - {{ series['completed'] | date:'longDate' }} ({{ series['aspect'] }})
        </label>
      </div>

    </div>

  </div>
</div>