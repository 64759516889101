import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { environment } from 'src/environments/environment';
import { Subject, BehaviorSubject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class AssessmentsService {
  private context = new BehaviorSubject<any>({});
  sessionContext$ = this.context.asObservable();

  private sidebar = new BehaviorSubject<any>({});
  viewContext$ = this.sidebar.asObservable();

  private action = new Subject<any>;
  actions$ = this.action.asObservable();

  constructor(private http: HttpClient) {}

  addSessionContext(context: object) {
    this.context.next({...this.context.value, ...context});
  }

  setSidebar(sidebar: object) {
    this.sidebar.next(sidebar);
  }

  pushAction(name: string, params: object={}) {
    this.action.next({...{'action': name}, ...params});
  }

  dashboard$(module_id: number=null) {
    let data = {}
    if(module_id) data['module_id'] = module_id;
    return this.http.post(`${environment.apiUri}/api/dashboard`, data);
  }

  assessmentCreate$(module_id: number, category_id: number, organisation_id: number, market_id: number) {
    var url = `${environment.apiUri}/api/assessment`;
    return this.http.post(url, {
      'module_id': module_id,
      'category_id': category_id,
      'organisation_id': organisation_id,
      'market_id': market_id
    });
  }

  assessmentResume$(assessment_id: string, path: string='') {
    var url = `${environment.apiUri}/api/assessment/${assessment_id}/resume?path=${path}`;
    return this.http.get(url);
  }

  assessmentResults$(assessment_id: string, path: string='') {
    var url = `${environment.apiUri}/api/assessment/${assessment_id}/results?path=${path}`;
    return this.http.get(url);
  }

  assessmentChart$(assessment_id: string) {
    var url = `${environment.apiUri}/api/assessment/${assessment_id}/chart`;
    return this.http.get(url);
  }

  assessmentDelete$(assessment_id: string) {
    // NB: this uses the REST API directly (/assessments rather than /assessment)
    var url = `${environment.apiUri}/api/assessments/${assessment_id}/`;
    return this.http.delete(url);
  }

  assessmentReopen$(assessment_id: string) {
    // NB: this uses the REST API directly (/assessments rather than /assessment)
    var url = `${environment.apiUri}/api/assessments/${assessment_id}/`;
    return this.http.patch(url, {
      'completed': null,
    });
  }

  assessmentCompleted$(assessment_id: string) {
    // NB: this uses the REST API directly (/assessments rather than /assessment)
    var url = `${environment.apiUri}/api/assessments/${assessment_id}/`;
    return this.http.patch(url, {
      'completed': new Date(),
    });
  }

  moduleList$() {
    return this.http.get(`${environment.apiUri}/api/modules`);
  }

  moduleTree$(module_version_id) {
    return this.http.get(`${environment.apiUri}/api/tree/${module_version_id}`);
  }

  areaQuestions$(area_id) {
    return this.http.get(`${environment.apiUri}/api/questions/?area_id=${area_id}/`);
  }

  // questionAnswers$(q_id) {
  //   return this.http.get(`${environment.apiUri}/api/answers/?question_id=${q_id}/`);
  // }

  questionsWithAnswers$(q_ids) {
    const ids = q_ids.join(',');
    return this.http.get(`${environment.apiUri}/api/questions-answers?question_ids=${ids}`);
  }

  answerEvidence$(a_id) {
    return this.http.get(`${environment.apiUri}/api/answerevidence/?answer_id=${a_id}/`);
  }

  userList$() {
    return this.http.get(`${environment.apiUri}/api/members/`);
  }

  userInvitations$() {
    var url = `${environment.apiUri}/api/invitations/`;
    return this.http.get(url);
  }

  inviteUser$(email: string, name: string, role: string) {
    return this.http.post(
      `${environment.apiUri}/api/invitations/`, {
        'email': email,
        'name': name,
        'role': role
    });
  }

  updateUser$(id: string, email: string, name: string, role: string) {
    return this.http.patch(
      `${environment.apiUri}/api/members/${id}/`, {
        'email': email,
        'name': name,
        'role': role
    });
  }

  updateAttachment$(id: string, text: string) {
    return this.http.patch(
      `${environment.apiUri}/api/attachments/${id}/`, {
        'content': text,
    });
  }

  updateMarket$(id: string, fields: object) {
    console.log(`update market ${id}: ${JSON.stringify(fields)}`);
    if(id) {
      return this.http.patch(
        `${environment.apiUri}/api/markets/${id}/`,
        fields
      );
    }
    else {
      return this.http.post(`${environment.apiUri}/api/markets/`, fields);
    }
  }

  updateRegion$(id: string, fields: object) {
    console.log(`update region ${id}: ${JSON.stringify(fields)}`);
    if(id) {
      return this.http.patch(
        `${environment.apiUri}/api/regions/${id}/`,
        fields
      );
    }
    else {
      return this.http.post(`${environment.apiUri}/api/regions/`, fields);
    }
  }

  updateCategory$(id: string, fields: object) {
    console.log(`update category ${id}: ${JSON.stringify(fields)}`);
    if(id) {
      return this.http.patch(
        `${environment.apiUri}/api/categories/${id}/`,
        fields
      );
    }
    else {
      return this.http.post(`${environment.apiUri}/api/categories/`, fields);
    }
  }

  updateOrganisation$(id: string, fields: object) {
    console.log(`update organisation ${id}: ${JSON.stringify(fields)}`);
    if(id) {
      return this.http.patch(
        `${environment.apiUri}/api/organisations/${id}/`,
        fields
      );
    }
    else {
      return this.http.post(`${environment.apiUri}/api/organisations/`, fields);
    }
  }

  setEvidenceLink$(id: string, button_label: string, link_url: string) {
    console.log(`setting evidence link #${id} to ${button_label} <${link_url}>`);
    return this.http.patch(
      `${environment.apiUri}/api/answer-evidence/${id}/`, {
        'link_label': button_label,
        'link_url': link_url,
    });
  }

  improvementActionCompleted$(action_id: number) {
    return this.http.patch(
      `${environment.apiUri}/api/improvement-actions/${action_id}/`, {
        'completed': new Date(),
    });
  }

  getPdfReport$(assessment_id: string, selected_paths: string[] = []) {
    return this.http.get(
      `${environment.apiUri}/api/assessment/${assessment_id}/pdf`, {
        responseType: 'blob',
        params: { recommendations: selected_paths.join(',') }
    });
  }

  addAnswer$(assessment_id, path, question_id, answer_id=null) {
    return this.http.post(
      `${environment.apiUri}/api/answers`, {
        'assessment_id': parseInt(assessment_id),
        'question_id': parseInt(question_id),
        'answer_id': parseInt(answer_id),
        'path': path
    });
  }

  addAction$(assessment_id, path, action_text) {
    return this.http.post(`${environment.apiUri}/api/actions`, {
      'assessment_id': parseInt(assessment_id),
      'path': path,
      'text': action_text
    });
  }

  addAttachment$(assessment_id, question_id, text) {
    return this.http.post(`${environment.apiUri}/api/attachments/`, {
      'assessment': parseInt(assessment_id),
      'question': parseInt(question_id),
      'content': text
    });
  }

  deleteAttachment$(attachment_id) {
    if(attachment_id) {
      return this.http.delete(`${environment.apiUri}/api/attachments/${attachment_id}/`);
    }
  }

  categories$() {
    return this.http.get(`${environment.apiUri}/api/categories/`);
  }

  regions$() {
    return this.http.get(`${environment.apiUri}/api/regions/`);
  }

  markets$() {
    return this.http.get(`${environment.apiUri}/api/markets/`);
  }

  organisations$() {
    return this.http.get(`${environment.apiUri}/api/organisations/`);
  }
}
