<div class="p-0 my-5">
  <h2>Training Recommendations</h2>

  <div class="accordion mt-3" id="training-recommendations">

    <div class="accordion-item" *ngFor="let r of data; let first = first">
      <h2 class="accordion-header" [id]="'heading' + r.id">
        <button
          [class]="'accordion-button' + (first ? ' show' : ' collapsed')"
          type="button"
          data-bs-toggle="collapse"
          [attr.data-bs-target]="'#collapse' + r.id"
          aria-expanded="true"
          [attr.aria-controls]="'collapse' + r.id"
        >
         <span style="width:30px">{{ r.training_module.code }}</span>
         {{ r.training_module.label }}
        </button>
      </h2>
      
      <div
        [id]="'collapse' + r.id"
        [class]="'accordion-collapse collapse' + (first ? ' show' : '')"
        [attr.aria-labelledby]="'heading' + r.id"
        data-bs-parent="#training-recommendations"
      >
        <div class="accordion-body">
          <div>
            <h3>Area Covered:</h3>
            <div>{{ r.training_module.scope }}</div>
          </div>
          <div class="progress-recommendations mt-3" *ngIf="r.progress_to">
            <h3>Recommendations to move to {{ r.progress_to }}</h3>
            <ul>
              <li *ngFor="let text of r.recommendation">
                {{ text }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>

  </div>
</div>