import { Component, Input } from '@angular/core';
import { AssessmentsService } from 'src/app/assessments.service';


@Component({
  selector: 'app-improvement-actions',
  templateUrl: './improvement-actions.component.html',
  styleUrls: ['./improvement-actions.component.css']
})
export class ImprovementActionsComponent {
  @Input() data: object;
  context: object;

  constructor(
    private assessments: AssessmentsService
  ) {}

  hasApiError = false;

  ngOnInit() {
    this.assessments.sessionContext$.subscribe({
      next: (context) => {
        this.context = context;
      }}
    );
  }

  setCompleted(event) {
    if(confirm('Are you sure you want to mark this action as completed?')) {
      const action_index = event.target.value;
      const action_id = this.data[action_index]['id'];
  
      this.assessments.improvementActionCompleted$(
        action_id
      ).subscribe({
        next: (res) => {
          this.hasApiError = false;
          this.data[action_index]['completed'] = new Date();
        },
        error: () => this.hasApiError = true,
      });
    }
  }
}
