<div class="p-0 my-5">
  <h2>Improvement Actions</h2>
  <table>
        <thead>
            <th style="width:100px">
                Date
                <img src="../../assets/icons/date-icon.svg" class="heading-icon" alt="" role="presentation" />
            </th>
            <th>
                Context
                <img src="../../assets/icons/assessment-icon.svg" class="heading-icon" alt="" role="presentation" />
            </th>
            <th *ngIf="context['staff_user']">
                Owner
                <img src="../../assets/icons/owner-icon.svg" class="heading-icon" alt="" role="presentation" />
            </th>
            <th>
                Action
                <img src="../../assets/icons/tools-icon.svg" class="heading-icon" alt="" role="presentation" />
            </th>
            <th>
                Status / Completed
                <img src="../../assets/icons/star-icon.svg" class="heading-icon" alt="" role="presentation" />
            </th>
        </thead>
        <tbody>
            <tr *ngFor="let action of data; let i = index" [id]="'action' + action.id">
                <td>{{action.date | date: 'yyyy-MM-dd'}}</td>
                <td>{{action.area}}</td>
                <td *ngIf="context['staff_user']">
                    {{ action.user }}
                </td>
                    <td>{{action.actions}}</td>
                <td [id]="'action' + action.id + '-status'">
                    {{action.completed | date: 'yyyy-MM-dd'}}
                    <button *ngIf="!action.completed" [value]="i" (click)="setCompleted($event)">
                        Complete
                    </button>
                </td>
            </tr>
        </tbody>
  </table>
</div>