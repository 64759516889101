import { Component, Inject } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { faUser, faPowerOff } from '@fortawesome/free-solid-svg-icons';
import { AuthService } from '@auth0/auth0-angular';
import { DOCUMENT } from '@angular/common';

const rolesKey: string = 'https://assessments.oliverwight-eame.com/roles';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.css']
})
export class HeaderComponent {
  isCollapsed = false;
  profile: object = {};

  userRoles: Array<string> = [];
  
  faUser = faUser;
  faPowerOff = faPowerOff;

  constructor(
    public auth: AuthService,
    private activatedRoute: ActivatedRoute,
    @Inject(DOCUMENT) private doc: Document
  ) {}

  ngOnInit() {
    this.auth.user$.subscribe(
      (profile) => {
        this.profile = profile;
        if(rolesKey in profile) {
          this.userRoles = profile[rolesKey];
        }
      }
    );
  }

  loginWithRedirect(): void {
    const { organization, invitation } = this.activatedRoute.snapshot.queryParams;
    this.auth.loginWithRedirect({
      authorizationParams: {
        organization,
        invitation
      }
    });
  }

  logout() {
    this.auth.logout({ logoutParams: { returnTo: this.doc.location.origin } });
  }
}
