<ul>
    <li *ngFor="let ancestor of data['area']['ancestors']; let i = index"
        class="navbox" [ngClass]="i == 0 ? 'module' : 'ancestor'"
    >
        <div class="nav-label d-flex">
            <div class="code">{{ ancestor.code | numberOrBlank }}</div>
            <a [routerLink]="['/results', context['assessment']['id']]"
                [queryParams]="{path: ancestor.path + '/' + ancestor.id}"
            >
                {{ ancestor.label }}
            </a>
        </div>
    </li>

    <li *ngFor="let sibling of data['area']['siblings']"
        class="navbox" [ngClass]="data['area']['ancestors'].length == 0 ? 'module' : data['area']['children'].length ? 'sibling' : 'child'"
    >
        <div class="nav-label d-flex" [ngClass]="data['area']['id'] == sibling['id'] ? 'selected' : ''">
            <div class="code">{{ sibling.code | numberOrBlank }}</div>
            <a [routerLink]="['/results', context['assessment']['id']]"
                [queryParams]="{path: sibling.path + '/' + sibling.id}"
            >{{ sibling.label }}</a>
        </div>

        <ul *ngIf="data['area']['id'] == sibling['id']" class="sibling-children">
            <li *ngFor="let node of data['area']['children']" class="navbox child">
                <div class="nav-label d-flex">
                    <div class="code">{{ node.code | numberOrBlank }}</div>
                    <a [routerLink]="['/results', context['assessment']['id']]"
                        [queryParams]="{path: node.path + '/' + node.id}"
                    >
                        {{ node.label }}
                    </a>
                </div>
            </li>
        </ul>
    </li>
</ul>
