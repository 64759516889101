import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { AuthService } from '@auth0/auth0-angular';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css']
})
export class LoginComponent {
  constructor(public auth: AuthService, private activatedRoute: ActivatedRoute) {}

  public ngOnInit(): void {
    const { organization, invitation } = this.activatedRoute.snapshot.queryParams;
    if(organization && invitation) {
      this.loginWithInvite(organization, invitation)
    }
  }

  loginWithInvite(organization: string, invitation: string): void {
    this.auth.loginWithRedirect({
      authorizationParams: {
        organization,
        invitation
      }
    });
  }
}
