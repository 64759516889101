<div class="my-5">
    <h2>Recent Assessments</h2>
    <table>
        <thead>
            <th style="width:100px">
                Date
                <img src="../../assets/icons/date-icon.svg" class="heading-icon" alt="" role="presentation" />
            </th>
            <!-- <th>
                Module
                <img src="../../assets/icons/assessment-icon.svg" class="heading-icon" alt="" role="presentation" />
            </th> -->
            <th *ngIf="context['staff_user']">
                Owner
                <img src="../../assets/icons/owner-icon.svg" class="heading-icon" alt="" role="presentation" />
            </th>
            <th>
                Aspect
                <img src="../../assets/icons/market-icon.svg" class="heading-icon" alt="" role="presentation" />
            </th>
            <th>
                Level
                <img src="../../assets/icons/status-icon.svg" class="heading-icon" alt="" role="presentation" />
            </th>
            <th *ngIf="context['staff_user']">
                Progress
                <img src="../../assets/icons/progress-icon.svg" class="heading-icon" alt="" role="presentation" />
            </th>
            <th>
                View / Resume
                <img src="../../assets/icons/view-icon.svg" class="heading-icon" alt="" role="presentation" />
            </th>
            <th>
                Delete
            </th>
        </thead>
        <tbody>
            <tr *ngFor="let assessment of data">
                <!-- Date -->
                <td>{{ assessment.date | date: 'yyyy-MM-dd' }}</td>

                <!-- Area -->
                <!-- <td>{{ assessment.area.label }}</td> -->

                <!-- Owner (admin only) -->
                <td *ngIf="context['staff_user']">{{ assessment.user }}</td>

                <!-- Aspect -->
                <td>{{ assessment.aspect }}</td>

                <!-- Level / Status -->
                <td>
                    <img src="/assets/icons/level-icon.svg"
                        [class]="'maturity' + assessment['score']['minimum']"
                        alt="" width="20" height="20"
                    />
                    <div class="level-label">
                        {{ assessment.level }}
                    </div>
                </td>

                <!-- Progress (admin only) -->
                <td *ngIf="context['staff_user'] && assessment.completed">
                    Completed <span style="white-space:nowrap;">
                        {{ assessment.completed | date: 'yyyy-MM-dd' }}
                    </span>
                </td>
                <td *ngIf="context['staff_user'] && !assessment.completed">
                    {{ assessment.progress.answer_count }} / {{ assessment.progress.question_count }}
                </td>

                <!-- Actions -->
                <td *ngIf="assessment.completed && assessment.owned">
                    <button [routerLink]="'/results/' + assessment.id" class="button">Report</button>
                    <button (click)="reopenAssessment(assessment.id)" class="button">Reopen</button>
                </td>
                <td *ngIf="!assessment.completed && assessment.owned">
                    <button [routerLink]="'/resume/' + assessment.id" class="button">Resume</button>
                </td>
                <td *ngIf="!assessment.completed && !assessment.owned && context['staff_user']">
                    <button [routerLink]="'/resume/' + assessment.id" class="button">View</button>
                </td>
                <td *ngIf="assessment.completed && !assessment.owned && context['staff_user']">
                    <button [routerLink]="'/results/' + assessment.id" class="button">Review</button>
                    <button (click)="reopenAssessment(assessment.id)" class="button">Reopen</button>
                </td>

                <!-- Delete -->
                <td class="delete-button">
                    <img class="delete-icon" src="/assets/icons/delete-icon.svg" alt="delete icon"
                        *ngIf="!assessment.completed && assessment.owned"
                        (click)="deleteAssessment(assessment.id)"
                    />
                </td>
            </tr>
        </tbody>
    </table>
</div>
