import { Component, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AssessmentsService } from 'src/app/assessments.service';


@Component({
  selector: 'app-edit-organisation',
  templateUrl: './edit-organisation.component.html',
  styleUrls: ['./edit-organisation.component.css']
})
export class EditOrganisationComponent {
  organisationForm: FormGroup;

  @Input() regions: object[] = [];
  @Input() categories: object[] = [];
  @Input() organisations: object[] = [];
  @Input() selectedOrganisation: number = -1;

  constructor(private assessments: AssessmentsService) {}

  ngOnInit() {
    this.organisationForm = new FormGroup({
      label: new FormControl('', Validators.required),
      region: new FormControl(''),
      category: new FormControl('')
    });
    this.updateOrganisationForm();
  }

  ngOnChanges(changes) {
    this.updateOrganisationForm();
  }

  updateOrganisationForm() {
    if(this.organisationForm) {
      if(this.selectedOrganisation >= 0) {
        var organisation = this.organisations[this.selectedOrganisation];
        this.organisationForm.controls.label.setValue(organisation['label']);
        this.organisationForm.controls.region.setValue(organisation['region_id']);
        this.organisationForm.controls.category.setValue(organisation['category_id']);
      }
      else {
        this.organisationForm.controls.label.setValue('');
        this.organisationForm.controls.region.setValue('');
        this.organisationForm.controls.category.setValue('');
      }
    }
  }

  onSubmit() {
    const id = this.selectedOrganisation >= 0 ? this.organisations[this.selectedOrganisation]['id'] : 0;
    this.assessments.updateOrganisation$(id, {
        'label': this.organisationForm.controls.label.value,
        'region_id': this.organisationForm.controls.region.value,
        'category_id': this.organisationForm.controls.category.value,
    }).subscribe({
      next: (res) => {
        if('id' in res) {
          if(this.selectedOrganisation >= 0) {
            this.organisations[this.selectedOrganisation] = res;
          }
          else {
            this.organisations.push(res);
          }
          // this.organisationForm.reset();
        }
      },
    });
  }

}
