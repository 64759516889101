<div class="view-switcher">
  <div>
    <img class="filter-icon" src="/assets/icons/filter-icon.svg" />
    Report View
  </div>
  <div class="view-selectors">
    <div>
      <input id="view0" type="radio" value="0" [(ngModel)]="resultsView">
      <label for="view0">Overall</label>
    </div>
    <div>
      <input id="view1" type="radio" value="1" [(ngModel)]="resultsView">
      <label for="view1">Summary</label>
    </div>
    <div>
      <input id="view2" type="radio" value="2" [(ngModel)]="resultsView">
      <label for="view2">No. of Questions</label>
    </div>
    <div>
      <input id="view3" type="radio" value="3" [(ngModel)]="resultsView">
      <label for="view3">% of Questions</label>
    </div>
    <div>
      <input id="view4" type="radio" value="4" [(ngModel)]="resultsView" (ngModelChange)="renderTopicScoresIfNeeded()">
      <label for="view4">Score per Topic</label>
    </div>
  </div>
</div>

<div class="d-flex mt-5" *ngIf="scores">
  <div class="maturity-levels">
    <img src="../../assets/ow-maturity-level-chart.svg" [ngStyle]="{'display': resultsView=='0' ? 'block': 'none'}" />
    <img src="../../assets/ow-maturity-level-chart2.svg" [ngStyle]="{'display': resultsView!='0' ? 'block': 'none'}" />
  </div>

  <div class="node-score" *ngIf="resultsView=='0'">
    <div
      [class]="'level-marker maturity' + scores['score']"
      [style]="'top: calc(' + markerTop + '% - ' + markerGapAdjustment + 'px)'"
    >
      <p class="score-title fw-bold">Level {{ scores['score'] }}: {{ scores['level'] }}</p>
      <p class="mean-score">Disconnected: {{ scores['disconnected'] }}</p>
    </div>
  </div>

  <div class="score-view">
    <div class="score-summary" *ngIf="resultsView=='0'">
      <div>
        <img src="/assets/icons/level-icon.svg"
          [class]="'score-badge maturity' + scores['score']"
          alt="maturity score icon" width="80"
        />
        <p class="fw-bold mt-2 mx-1 level-label">{{ scores['level'] }}</p>
      </div>
      <p class="mx-1 level-text">Maturity score description/scoring information.</p>
    </div>

    <table *ngIf="resultsView!='0' && resultsView!='4'" class="breakdown-areas">
      <th class="level-number">&nbsp;</th>
      <th *ngFor="let area_label of scores['breakdown']['areas']">
        {{ area_label }}
      </th>
    </table>

    <div class="score-summary" *ngIf="resultsView=='1'">
      <table>
        <tr *ngFor="let score of Object.keys(scores['breakdown']['levels']).reverse()"
            [class]="'maturity' + score + ' maturity' + score + '-bg'"
        >
          <td class="level-number">
            <img [src]="'/assets/level-label' + score + '.svg'" />
          </td>
          <td *ngFor="let area of scores['breakdown']['areas']; let i = index">
            <div *ngIf="score && scores['breakdown']['scores_by_area'][i] == score && scores['breakdown']['totals'][i]">
              <img src="/assets/icons/level-icon.svg"
                [class]="'score-badge maturity' + score"
                alt="maturity score icon" width="36"
              />
            </div>
            <div *ngIf="score == 0 && scores['breakdown']['totals'][i]">
              {{ scores['breakdown']['disconnected_by_area'][i] }}
            </div>
          </td>
        </tr>
      </table>
    </div>

    <div class="score-counts" *ngIf="resultsView=='2'">
      <table>
        <tr *ngFor="let level of scores['breakdown']['count_by_level']"
            [class]="'maturity' + level['score'] + ' maturity' + level['score'] + '-bg'"
        >
          <td class="level-number">
            <img [src]="'/assets/level-label' + level['score'] + '.svg'" />
          </td>
          <td *ngFor="let area of level['by_area']; let i = index">
            <div *ngIf="scores['breakdown']['totals'][i]">
              {{ area }}
            </div>
          </td>
        </tr>
        <tr [class]="'total-line maturity' + scores['score'] + ' maturity' + scores['score'] + '-bg'">
          <td class="total-label">
            <img src="/assets/total-label.svg" [class]="'maturity' + scores['score']" />
          </td>
          <td *ngFor="let total of scores['breakdown']['totals']">
            <div *ngIf="total">
              {{ total }}
            </div>
          </td>
        </tr>
      </table>
    </div>

    <div class="score-percent" *ngIf="resultsView=='3'">
      <table>
        <tr *ngFor="let level of scores['breakdown']['count_by_level']"
            [class]="'maturity' + level['score'] + ' maturity' + level['score'] + '-bg'"
        >
          <td class="level-number">
            <img [src]="'/assets/level-label' + level['score'] + '.svg'" />
          </td>
          <td *ngFor="let area_score of level['by_area']; let i = index">
            <div *ngIf="scores['breakdown']['totals'][i]">
              {{ area_score / scores['breakdown']['totals'][i] | percent }}
            </div>
          </td>
        </tr>
      </table>
    </div>

    <div class="score-topics" [ngStyle]="{'display': resultsView=='4' ? 'block': 'none'}">
      <canvas id="topic-scores">{{ chartjs }}</canvas>
    </div>
  </div>

</div>
