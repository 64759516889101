import { Component } from '@angular/core';

@Component({
  selector: 'app-module-maturity',
  templateUrl: './module-maturity.component.html',
  styleUrls: ['./module-maturity.component.css']
})
export class ModuleMaturityComponent {

}
