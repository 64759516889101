import { Component, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AssessmentsService } from 'src/app/assessments.service';

@Component({
  selector: 'app-edit-user',
  templateUrl: './edit-user.component.html',
  styleUrls: ['./edit-user.component.css']
})
export class EditUserComponent {
  userForm: FormGroup;

  @Input() users: object[] = [];
  @Input() selectedUser: number = -1;

  constructor(private assessments: AssessmentsService) {}

  ngOnInit() {
    this.userForm = new FormGroup({
      name: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      role: new FormControl('', Validators.required)
    });
    this.updateUserForm();
  }

  ngOnChanges(changes) {
    this.updateUserForm();
  }

  updateUserForm() {
    if(this.userForm && this.selectedUser >= 0) {
      var user = this.users[this.selectedUser];
      this.userForm.controls.email.setValue(user['email']);
      this.userForm.controls.name.setValue(user['name']);
      if(user['user_metadata']) {
        this.userForm.controls.role.setValue(user['user_metadata']['function']);
      }
    }
  }

  onSubmit() {
    console.log(this.userForm);
    this.assessments.updateUser$(
      this.users[this.selectedUser]['user_id'],
      this.userForm.controls.email.value,
      this.userForm.controls.name.value,
      this.userForm.controls.role.value,
    ).subscribe({
      next: (res) => {
        if('user_id' in res) {
          this.users[this.selectedUser] = res;
          this.userForm.reset();
        };
      },
      // error: () => this.hasApiError = true,
    })
  }
}
