import { Component, Input, Output, EventEmitter } from '@angular/core';

@Component({
  selector: 'app-sidebar-results-nav',
  templateUrl: './sidebar-results-nav.component.html',
  styleUrls: ['./sidebar-results-nav.component.css']
})
export class SidebarResultsNavComponent {
  @Input() data: object = {};
  @Input() context: object = {};
  @Output() navigation: EventEmitter<object> = new EventEmitter<object>();

  navMoveToQuestion(index: number) {
    this.navigation.emit({
      'q_index': index
    });
  }
}
