<nav ngbNav #nav="ngbNav" class="nav-tabs">
    <ng-container ngbNavItem>
        <a ngbNavLink>Markets</a>
        <ng-template ngbNavContent>
            <button class="add-button" (click)="addMarket()">
                Add Market
            </button>
            <p>
                Markets are used as reporting aspects in the IBP Assessment module.
                Please take care if editing existing Markets as this will also change any
                existing assessments that are already linked to that market.
            </p>
            <app-edit-market
                [markets]="markets"
                [regions]="regions"
                [selectedMarket]="selectedMarket"
                *ngIf="showMarketsForm"
            />
            <br />
            <h2>All Markets</h2>
            <table>
                <thead>
                    <th>Region</th>
                    <th>Label</th>
                    <th>Enabled</th>
                </thead>
                <tbody>
                    <tr *ngFor="let m of markets">
                        <td>{{ m.region }}</td>
                        <td>{{ m.label }}</td>
                        <td class="form-check form-switch">
                            <input mdbCheckbox type="checkbox" class="form-check-input"
                                [checked]="m.enabled"
                                (change)="setMarketStatus(m.id, $event.target.checked)"
                            />
                        </td>
                        <td>
                            <button (click)="selectMarket(m.id)">Edit</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-template>
    </ng-container>

    <ng-container ngbNavItem>
        <a ngbNavLink>Regions</a>
        <ng-template ngbNavContent>
            <button class="add-button" (click)="addRegion()">
                Add Region
            </button>
            <p>
                Regions are used as reporting aspects and organisational units in both the
                IBP and Supply Chain assessment modules.

                Please take care if editing existing Regions as this will also change any
                existing assessments that are already linked to that region.
            </p>
            <app-edit-region
                [regions]="regions"
                [selectedRegion]="selectedRegion"
                *ngIf="showRegionForm"
            />
            <br />
            <h2>All Regions</h2>
            <table>
                <thead>
                    <!-- <th>Code</th> -->
                    <th>Label</th>
                    <th>Enabled</th>
                </thead>
                <tbody>
                    <tr *ngFor="let r of regions">
                        <!-- <td>{{ r.code }}</td> -->
                        <td>{{ r.label }}</td>
                        <td class="form-check form-switch">
                            <input mdbCheckbox type="checkbox" class="form-check-input"
                                [checked]="r.enabled"
                                (change)="setRegionStatus(r.id, $event.target.checked)"
                            />
                        </td>
                        <td>
                            <button (click)="selectRegion(r.id)">Edit</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-template>
    </ng-container>

    <ng-container ngbNavItem>
        <a ngbNavLink>Categories</a>
        <ng-template ngbNavContent>
            <button class="add-button" (click)="addCategory()">
                Add Category
            </button>
            <p>
                Categories are used as reporting aspects and organisational units in both the
                IBP and Supply Chain assessment modules.

                Please take care if editing existing Categories as this will also change any
                existing assessments that are already linked to that category.
            </p>
            <app-edit-category
                [categories]="categories"
                [selectedCategory]="selectedCategory"
                *ngIf="showCategoryForm"
            />
            <br />
            <h2>All Categories</h2>
            <table>
                <thead>
                    <!-- <th>Code</th> -->
                    <th>Label</th>
                    <th>Enabled</th>
                </thead>
                <tbody>
                    <tr *ngFor="let c of categories">
                        <!-- <td>{{ r.code }}</td> -->
                        <td>{{ c.label }}</td>
                        <td class="form-check form-switch">
                            <input mdbCheckbox type="checkbox" class="form-check-input"
                                [checked]="c.enabled"
                                (change)="setCategoryStatus(c.id, $event.target.checked)"
                            />
                        </td>
                        <td>
                            <button (click)="selectCategory(c.id)">Edit</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-template>
    </ng-container>

    <ng-container ngbNavItem>
        <a ngbNavLink>Organizations</a>
        <ng-template ngbNavContent>
            <button class="add-button" (click)="addOrganisation()">
                Add Organization
            </button>
            <p>
                An Organization is defined as either a Region or a Category and these are used to refine
                applicable assessment content as well as reporting aspects in Supply Chain assessments.
            </p>
            <app-edit-organisation
                [regions]="regions"
                [categories]="categories"
                [organisations]="organisations"
                [selectedOrganisation]="selectedOrganisation"
                *ngIf="showOrganisationForm"
            />
            <br />
            <h2>All Organizations</h2>
            <table>
                <thead>
                    <!-- <th>Code</th> -->
                    <th>Label</th>
                    <th>Region</th>
                    <th>Category</th>
                    <th>Enabled</th>
                </thead>
                <tbody>
                    <tr *ngFor="let o of organisations">
                        <!-- <td>{{ r.code }}</td> -->
                        <td>{{ o.label }}</td>
                        <td>{{ o.region }}</td>
                        <td>{{ o.category }}</td>
                        <td class="form-check form-switch">
                            <input mdbCheckbox type="checkbox" class="form-check-input"
                                [checked]="o.enabled"
                                (change)="setOrganisationStatus(o.id, $event.target.checked)"
                            />
                        </td>
                        <td>
                            <button (click)="selectOrganisation(o.id)">Edit</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-template>
    </ng-container>

    <ng-container ngbNavItem>
        <a ngbNavLink>Users</a>
        <ng-template ngbNavContent>
            <p class="my-3">
                Here you can make changes to the email address, name, function or status of existing members of your Organization.
            </p>
            <app-edit-user [users]="users" [selectedUser]="selectedUser" />
            <br />
            <h2>Current Users</h2>
            <table>
                <thead>
                    <th>Name</th>
                    <th>Email</th>
                    <th>Function</th>
                    <th>Actions</th>
                </thead>
                <tbody>
                    <tr *ngFor="let user of users">
                        <td>{{ user.name }}</td>
                        <td>{{ user.email }}</td>
                        <td>{{ user.user_metadata?.function }}</td>
                        <td>
                            <button (click)="selectUser(user.user_id)">Edit</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-template>
    </ng-container>

    <ng-container ngbNavItem>
        <a ngbNavLink>Invites</a>
        <ng-template ngbNavContent>
            <p>

            </p>
            <app-invite-user />
            <br />
            <h2>Pending Invitations</h2>
            <table>
                <thead>
                    <th>Email</th>
                    <th>Expires</th>
                    <th>Metadata</th>
                    <th>Actions</th>
                </thead>
                <tbody>
                    <tr *ngFor="let invite of invites">
                        <td>{{ invite.invitee.email }}</td>
                        <td>{{ invite.expires_at | date:'medium' }}</td>
                        <td>
                            <div *ngFor="let kv of invite.user_metadata | keyvalue">
                                <b>{{ kv.key | titlecase }}:</b> {{ kv.value }}
                            </div>
                        </td>
                        <td>
                            <button (click)="resendInvite(invite.id)">Resend</button>
                        </td>
                    </tr>
                </tbody>
            </table>
        </ng-template>
    </ng-container>

    <ng-container ngbNavItem>
        <a ngbNavLink>Evidence</a>
        <ng-template ngbNavContent>
            <p class="mt-3">
                Use the selection boxes below to find a specific question and answer in the relevant assessment module.
            </p>
            <app-question-picker />
        </ng-template>
    </ng-container>

    <!-- <ng-container ngbNavItem [disabled]="true">
        <a ngbNavLink>Training Modules</a>
        <ng-template ngbNavContent>
            <p>

            </p>
        </ng-template>
    </ng-container> -->
</nav>

<div [ngbNavOutlet]="nav" class="mt-2"></div>
