<p>
</p>

<form [formGroup]="organisationForm" (ngSubmit)="onSubmit()" class="container">
    <div class="row py-0" *ngIf="selectedOrganisation < 0">
        <p>Create a new Organization:</p>
    </div>
    <div class="row py-0" *ngIf="selectedOrganisation >= 0">
        <p>
            You are changing the existing Organization called
            '{{ organisations[selectedOrganisation]['label'] }}':
        </p>
    </div>
    <div class="row">
        <div class="col-4">
            <label for="label">Label:</label>
            <input type="text" id="label" formControlName="label">
        </div>
        <div class="col-4">
            <label for="region">Region:</label>
            <select id="region" formControlName="region">
                <option></option>
                <option *ngFor="let r of regions" [value]="r.id">
                    {{ r.label }}
                </option>
            </select>
        </div>
        <div class="col-4">
            <label for="code">Category:</label>
            <select id="category" formControlName="category">
                <option></option>
                <option *ngFor="let c of categories" [value]="c.id">
                    {{ c.label }}
                </option>
            </select>
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <button type="submit"
                *ngIf="selectedOrganisation < 0"
                [disabled]="organisationForm.invalid"
            >
                Create
            </button>
            <button type="submit"
                *ngIf="selectedOrganisation >= 0"
                [disabled]="organisationForm.invalid"
            >
                Save
            </button>
        </div>
    </div>
</form>