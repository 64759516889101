import { Component } from '@angular/core';
import { AssessmentsService } from 'src/app/assessments.service';
import { Pipe, PipeTransform } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';


@Pipe({name: 'padlevel'})
export class PadLevelPipe implements PipeTransform {
  transform(value: string): string {
    return String.fromCharCode(160).repeat((value.split('/').length - 2) * 4);
  }
}

@Component({
  selector: 'app-question-picker',
  templateUrl: './question-picker.component.html',
  styleUrls: ['./question-picker.component.css']
})
export class QuestionPickerComponent {
  modules: any = [];
  areas: any = [];
  questions: any = [];
  answers: any = [];
  evidence: object[] = [];

  selectedModule: number = 0;
  selectedPath: string = '';
  selectedQuestion: number = 0;
  selectedAnswer: number = 0;

  evidence_forms = {};

  constructor(
    public assessments: AssessmentsService
  ) {}

  ngOnInit() {
    this.assessments.moduleList$().subscribe({
      next: (res) => {
        this.modules = res;
      },
      error: () => console.log('failed to get module list'),
    });
  }

  setModule(version_id) {
    console.log(`module selected: ${version_id}`);
    this.clearAreas();

    this.assessments.moduleTree$(version_id).subscribe({
      next: (res) => {
        this.areas = res;
      },
      error: () => console.log('failed to get module areas tree'),
    });

    this.selectedModule = version_id;
  }

  setArea(path: string) {
    console.log(`area path selected: ${path}`);
    this.clearQuestions();

    const q_ids = this.areas['paths'][path]['questions'];
    // TODO: support getting multiple questions in one call
    this.questions = [];
    this.assessments.questionsWithAnswers$(q_ids).subscribe({
      next: (res) => {
        this.questions = res;
        // console.log(this.questions);
      },
      error: () => console.log(`failed to get data for questions: ${q_ids}`),
    });

    this.selectedPath = path;
  }

  setQuestion(q_id) {
    console.log(`question selected: ${q_id}`);
    this.clearAnswers();

    this.answers = this.questions[q_id]['answers'];
    this.selectedQuestion = this.questions[q_id];
  }

  setAnswer(a_id) {
    const URL_REGEXP = /^[A-Za-z][A-Za-z\d.+-]*:\/*(?:\w+(?::\w+)?@)?[^\s/]+(?::\d+)?(?:\/[\w#!:.?+=&%@\-/]*)?$/;
    this.evidence = this.selectedQuestion['answers'][a_id]['evidence']

    Object.entries(this.evidence).map(([id, e]) => {
      var evidence_link_form: FormGroup = new FormGroup({
        button_label: new FormControl(
          e['link_label'],
          Validators.required
        ),
        link_url: new FormControl(
          e['link_url'],
          [Validators.required, Validators.pattern(URL_REGEXP)]
        ),
      });
      this[`evidence${id}`] = evidence_link_form;
    });
    this.selectedAnswer = this.selectedQuestion['answers'][a_id];
  }

  onSubmit(id) {
    const form = this[`evidence${id}`];
    this.assessments.setEvidenceLink$(
      id,
      form.controls.button_label.value,
      form.controls.link_url.value
    ).subscribe({
      next: (res) => {
        console.log(res);
      }
    })
  }

  clearAreas() {
    this.selectedPath = '';
    this.areas = [];
    this.clearQuestions();
  }

  clearQuestions() {
    this.selectedQuestion = 0;
    this.questions = [];
    this.clearAnswers();
  }

  clearAnswers() {
    this.selectedAnswer = 0;
    this.answers = [];
    this.clearEvidence();
  }

  clearEvidence() {
    this.evidence = [];
  }
}
