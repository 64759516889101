import { Component, Input } from '@angular/core';
import { AssessmentsService } from 'src/app/assessments.service';

@Component({
  selector: 'app-nav-bar',
  templateUrl: './nav-bar.component.html',
  styleUrls: ['./nav-bar.component.css'],
})
export class NavBarComponent {
  isCollapsed = true;
  context: object = {
    'title': 'Connecting...',
  };

  constructor(private assessments: AssessmentsService) {}

  ngOnInit() {
    this.assessments.sessionContext$.subscribe({
      next: (context) => {
        this.context = context;
      }}
    );
  }
  
}
