<p>
    You can invite a user to join your Organization using the form below.
    An email will be sent to the address specified containing an invitation link, valid for 30 days.
    Once the user clicks the invitation link and sets up their password, they will have access to use the portal.
</p>

<form [formGroup]="inviteForm" (ngSubmit)="onSubmit()" class="container">
    <div class="row">
        <div class="col-6">
            <label for="name">Name:</label>
            <input type="text" id="name" formControlName="name">
        </div>
        <div class="col-6">
            <label for="role">Function:</label>
            <input type="text" id="role" formControlName="role">
        </div>
    </div>
    <div class="row">
        <div class="col-6">
            <label for="email">Email:</label>
            <input type="email" id="email" formControlName="email">
        </div>
  
        <div class="col-6">
            <button type="submit" [disabled]="inviteForm.invalid">Send</button>
        </div>
    </div>
</form>