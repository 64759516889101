import { Component, EventEmitter, Output, ViewChild, ElementRef } from '@angular/core';
import { AssessmentsService } from 'src/app/assessments.service';

@Component({
  selector: 'app-admin',
  templateUrl: './admin.component.html',
  styleUrls: ['./admin.component.css']
})

export class AdminComponent {
  users: any = [];
  markets: any = [];
  regions: any = [];
  categories: any = [];
  organisations: any = [];
  invites: any = [];

  showMarketsForm: boolean = false;
  showRegionForm: boolean = false;
  showCategoryForm: boolean = false;
  showOrganisationForm: boolean = false;

  selectedUser: number = -1;
  selectedMarket: number = -1;
  selectedRegion: number = -1;
  selectedCategory: number = -1;
  selectedOrganisation: number = -1;

  constructor(
    public assessments: AssessmentsService
  ) {}

  ngOnInit() {
    // TODO: can we fetch some of this data only if the relevant tab is selected?

    this.assessments.userList$().subscribe({
      next: (res) => {
        this.users = res;
      },
      error: () => console.log('failed to get user list'),
    });
    this.assessments.userInvitations$().subscribe({
      next: (res) => {
        this.invites = res;
      },
      error: () => console.log('failed to get invitations list'),
    });
    this.assessments.markets$().subscribe({
      next: (res) => {
        this.markets = res;
      },
      error: () => console.log('failed to get markets list'),
    });
    this.assessments.regions$().subscribe({
      next: (res) => {
        this.regions = res;
      },
      error: () => console.log('failed to get regions list'),
    });
    this.assessments.categories$().subscribe({
      next: (res) => {
        this.categories = res;
      },
      error: () => console.log('failed to get categories list'),
    });
    this.assessments.organisations$().subscribe({
      next: (res) => {
        this.organisations = res;
      },
      error: () => console.log('failed to get organisations list'),
    });

    this.assessments.addSessionContext({
      'title': 'Administration Area',
      'sub_title': 'Configuration & User Management'
    })
    this.assessments.setSidebar({
      'nav': 'info',
      'title': 'Administrator Support',
      'message': 'Please <a href="mailto:assessment@oliverwight.net">contact our team</a> to help you with any administration tasks that are not available here.'
    })

    this.assessments.actions$.subscribe(
      (action) => (this.doAction(action))
    );
  }

  doAction(event: object) {
    switch(event['action']) {
      case 'add_invite':
        this.invites.push(event);
        break;
    }
  }

  resendInvite(id: string) {
    for (var i = 0; i < this.invites.length; i++) { 
      if(this.invites[i].id == id) {
        this.assessments.inviteUser$(
          this.invites[i].invitee.email,
          this.invites[i].user_metadata.name,
          this.invites[i].user_metadata.function,
        ).subscribe({
          next: (res) => {
            if('id' in res) {
              this.invites = this.invites.filter(item => item.id !== id);
              this.invites.unshift(res);
            }
          }
        });
      };
    }
  }

  selectUser(id: string) {
    for (var i = 0; i < this.users.length; i++) { 
      if(this.users[i].user_id == id) {
        this.selectedUser = i;
        document.getElementsByTagName('nav')[0].scrollIntoView();
      }
    }
  }

  addMarket() {
    this.selectedMarket = -1;
    this.showMarketsForm = true;
  }

  selectMarket(id: string) {
    this.showMarketsForm = true;
    for (var i = 0; i < this.markets.length; i++) { 
      if(this.markets[i].id == id) {
        this.selectedMarket = i;
        document.getElementsByTagName('nav')[0].scrollIntoView();
      }
    }
  }

  setMarketStatus(id: string, enabled: boolean) {
    this.assessments.updateMarket$(id, {
      'enabled': enabled
    }).subscribe({
      next: (res) => {
        console.log(res);
      }
    });
  }

  addRegion() {
    this.selectedRegion = -1;
    this.showRegionForm = true;
  }

  selectRegion(id: string) {
    console.log('Editing Region: ' + id);
    this.showRegionForm = true;
    for (var i = 0; i < this.regions.length; i++) { 
      if(this.regions[i].id == id) {
        this.selectedRegion = i;
        document.getElementsByTagName('nav')[0].scrollIntoView();
      }
    }
  }

  setRegionStatus(id: string, enabled: boolean) {
    this.assessments.updateRegion$(id, {
      'enabled': enabled
    }).subscribe({
      next: (res) => {
        console.log(res);
      }
    });  }

  addCategory() {
    this.selectedCategory = -1;
    this.showCategoryForm = true;
  }

  selectCategory(id: string) {
    console.log('Editing Category: ' + id);
    this.showCategoryForm = true;
    for (var i = 0; i < this.categories.length; i++) { 
      if(this.categories[i].id == id) {
        this.selectedCategory = i;
        document.getElementsByTagName('nav')[0].scrollIntoView();
      }
    }
  }

  setCategoryStatus(id: string, enabled: boolean) {
    this.assessments.updateCategory$(id, {
      'enabled': enabled
    }).subscribe({
      next: (res) => {
        console.log(res);
      }
    });
  }

  addOrganisation() {
    this.selectedOrganisation = -1;
    this.showOrganisationForm = true;
  }

  selectOrganisation(id: string) {
    this.showOrganisationForm = true;
    for (var i = 0; i < this.organisations.length; i++) { 
      if(this.organisations[i].id == id) {
        this.selectedOrganisation = i;
        document.getElementsByTagName('nav')[0].scrollIntoView();
      }
    }
  }

  setOrganisationStatus(id: string, enabled: boolean) {
    this.assessments.updateOrganisation$(id, {
      'enabled': enabled
    }).subscribe({
      next: (res) => {
        console.log(res);
      }
    });
  }

}
