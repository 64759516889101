<div class="row">
    <div class="col-6 control-group">
        <label for="module">Assessment Module:</label>
        <select id="module" (change)="setModule($event.target.value)">
            <option disabled selected value>Select a Module</option>
            <option *ngFor="let m of modules" [value]="m.version_id">
                {{ m.label }}
            </option>
        </select>
    </div>

    <div class="col-6 control-group">
        <label for="area">Assessment Area:</label>
        <select id="area" (change)="setArea($event.target.value)">
            <option disabled [selected]="selectedPath == ''" value="">
                Select Assessment Area
            </option>
            <option *ngFor="let a of areas['paths'] | keyvalue" [value]="a.key">
                {{ a.key | padlevel }}{{ a.value.label }}
            </option>
        </select>
    </div>

    <div class="col-12 control-group">
        <label for="question">Assessment Question:</label>
        <select id="question" (change)="setQuestion($event.target.value)">
            <option disabled [selected]="selectedQuestion == 0" value="0">
                Select Question
            </option>
            <option *ngFor="let q of questions | keyvalue" [value]="q.key">
                {{ q.value.synopsis }}
            </option>
        </select>
    </div>

    <div class="col-12 control-group">
        <label for="answer">Question Answer:</label>
        <select id="answer" (change)="setAnswer($event.target.value)">
            <option disabled [selected]="selectedAnswer == 0" value="0">
                Select Answer
            </option>
            <option *ngFor="let ans of answers | keyvalue" [value]="ans.key">
                {{ ans.value.score }}: {{ ans.value.text }}
            </option>
        </select>
    </div>

    <div *ngIf="selectedAnswer" class="answer-evidence">
        <label>Answer Evidence:</label>

        <div class="evidence-boxes">
            <div *ngFor="let e of evidence | keyvalue" class="evidence-box">
                <div class="evidence-text">
                    <h3>#{{ e.value['evidence']['code'] }} {{ e.value['evidence']['type'] }}</h3>
                    <div [innerHTML]="e.value['evidence']['text']"></div>
                    <h4 style="display:none">Test</h4>
                </div>

                <div class="evidence-links">
                    <div *ngIf="e.value['evidence']['link_url']">
                        <h4>Default Evidence Link</h4>
                        <p>
                            The default evidence link that will be used for all answers linked to this evidence code.
                            These can only be updated via the template spreadsheet.
                        </p>
                        <input disabled [value]="e.value['evidence']['link_label']" />
                        <input disabled [value]="e.value['evidence']['link_url']" />
                    </div>

                    <form [formGroup]="this['evidence' + e.key]" (ngSubmit)="onSubmit(e.key)">
                        <div>
                            <h4>Specific Evidence Link</h4>
                            <p>
                                You can override the evidence link and button label that will be used for this specific
                                question/answer in the assessment.
                            </p>
                            <div>
                                <label>Button Label:</label>
                                <div class="label-note">Maximum length 20 characters</div>
                                <input formControlName="button_label" />
                            </div>
                            <div>
                                <label>Link URL:</label>
                                <div class="label-note">Maximum length 250 characters</div>
                                <input formControlName="link_url" />
                            </div>
                            <button type="submit" [disabled]="this['evidence' + e.key].invalid">
                                Save
                            </button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
</div>