import { Component, Input } from '@angular/core';

@Component({
  selector: 'app-training-recommendations',
  templateUrl: './training-recommendations.component.html',
  styleUrls: ['./training-recommendations.component.css']
})
export class TrainingRecommendationsComponent {
  @Input() data: object;
}
