import { Component, Input } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { AssessmentsService } from 'src/app/assessments.service';
import { Router } from '@angular/router';


@Component({
  selector: 'app-sidebar-new-assessment',
  templateUrl: './sidebar-new-assessment.component.html',
  styleUrls: ['./sidebar-new-assessment.component.css']
})
export class SidebarNewAssessmentComponent {
  constructor(
    public auth: AuthService,
    private router: Router,
    private assessments: AssessmentsService
  ) {}

  @Input() data: object = {};

  context: object;
  profile: object;
  module_id: number = 0;

  category: boolean = false;
  category_id: number = 1;
  categories: Array<object>;

  // region: boolean = false;
  // region_id: number = 1;
  // regions: object;

  organisation: boolean = false;
  organisation_id: number = 1;
  organisations: Array<object>;

  filter_category: boolean = false;
  filtered_orgs: Array<object> = [];

  market: boolean = false;
  market_id: number = 1;
  markets: Array<object>;

  ngOnInit() {
    this.assessments.sessionContext$.subscribe(
      (context) => (this.context = context)
    );

    this.auth.user$.subscribe(
      (profile) => (this.profile = profile)
    );

    this.assessments.categories$().subscribe({
      next: (res) => {
        if(Array.isArray(res)) {
          this.categories = res.filter(cat => cat.enabled);;
        }
        else {
          this.categories = [];
        }
        if(this.categories.length) {
          this.setCategory(this.categories[0]['id'])
        }
      },
      error: () => console.log('failed to get categories list'),
    });

    this.assessments.organisations$().subscribe({
      next: (res) => {
        if(Array.isArray(res)) {
          this.organisations = res.filter(org => org.enabled);;
          if(this.category_id && this.filter_category) {
            this.filtered_orgs = this.organisations.filter(o => { return parseInt(o['category_id']) == this.category_id });
          }
        }
        else {
          this.organisations = [];
        }
        if(this.organisations.length) {
          this.setOrganisation(this.organisations[0]['id'])
        }
      },
      error: () => console.log('failed to get organisations list'),
    });

    this.assessments.markets$().subscribe({
      next: (res) => {
        if(Array.isArray(res)) {
          this.markets = res.filter(mkt => mkt.enabled);;
        }
        else {
          this.markets = [];
        }
        if(this.markets.length) {
          this.setMarket(this.markets[0]['id'])
        }
      },
      error: () => console.log('failed to get markets list'),
    });
  }

  ngOnChanges() {
    var module_id = localStorage.getItem('module');
    if(this.data['modules'].filter(m => { return m['id'] == module_id }).length == 0) {
      // console.log(`module_id ${module_id} from local storage not found in modules list`);
      module_id = this.data['modules'][0]['id'];
    }
    this.setModule(module_id);
  }

  setModule(module_id: string) {
    this.module_id = parseInt(module_id);
    const module = this.data['modules'].filter(m => { return m['id'] == this.module_id })[0];

    this.filter_category = module['filter_category'];

    this.category = module['by_category'];
    this.market = module['by_market'];
    this.organisation = module['by_organisation'];
  }
  setCategory(category_id: string) {
    this.category_id = parseInt(category_id);
    if(this.organisations && this.filter_category) {
      this.filtered_orgs = this.organisations.filter(o => { return o['category_id'] == category_id });
      this.organisation_id = this.filtered_orgs[0]['id'];
    }
  }
  setOrganisation(organisation_id: string) {
    this.organisation_id = parseInt(organisation_id);
  }
  setMarket(market_id: string) {
    this.market_id = parseInt(market_id);
  }

  startAssessment() {
    this.assessments.assessmentCreate$(
        this.module_id,
        this.category ? this.category_id : null,
        this.organisation ? this.organisation_id : null,
        this.market ? this.market_id: null
      ).subscribe({
      next: (res) => {
        this.router.navigate(['/resume', res['id']]);
      },
      error: () => console.log('failed to start new assessment'),
    });
  }
}
