<app-sidebar-new-assessment
    *ngIf="sidebar['nav'] === 'dashboard'"
    [data]="sidebar"
/>
<app-sidebar-areas-nav
    *ngIf="sidebar['nav'] === 'assessment'"
    [data]="sidebar"
/>
<app-sidebar-results-nav
    *ngIf="sidebar['nav'] === 'results'"
    [data]="sidebar"
    [context]="context"
/>
<app-sidebar-info
    *ngIf="sidebar['nav'] === 'info'"
    [data]="sidebar"
/>
