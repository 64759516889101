import { Component, OnInit } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { AssessmentsService } from 'src/app/assessments.service';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css'],
})
export class ProfileComponent implements OnInit {
  profileJson: string = null;
  context: object = {};

  constructor(public auth: AuthService, public assessments: AssessmentsService) {}

  ngOnInit() {
    this.auth.user$.subscribe(
      (profile) => {
        this.profileJson = JSON.stringify(profile, null, 2);
        this.assessments.addSessionContext({ "user_roles": profile['https://assessments.oliverwight-eame.com/roles'] });
      }
    );
    this.assessments.sessionContext$.subscribe({
      'next': (x) => { this.context = x; }
    })
  }
}
