<div *ngIf="hasApiError" class="alert alert-danger" role="alert">
  An error occured when trying to call the assessments endpoint.
</div>

<app-loading *ngIf="isLoading; else loaded" />
<ng-template #loaded>
  <app-score-breakdown *ngIf="assessment['scores']" [scores]="assessment['scores']" />

  <app-training-recommendations *ngIf="training?.length > 0" [data]="training" />

  <div class="p-0 my-5">
    <h2>Recommendations for improvement</h2>

    <!-- <h3 class="mt-5">Improvement focus areas</h3> -->

    <div class="accordion mt-3"
      [id]="'recommendations' + i"
      *ngFor="let node of recommendations; let i=index"
    >

      <h3 class="mt-4">{{ node.area }}</h3>

      <div class="accordion-item" *ngFor="let r of node.recommendations; let first = first">
        <input class="include-report" type="checkbox" checked
          (change)="selectRecommendation(r.id)"
          [value]="r.path"
        />

        <h2 class="accordion-header" [id]="'heading' + r.id">
          <button
            [class]="'accordion-button' + (first ? ' show' : ' collapsed')"
            type="button"
            data-bs-toggle="collapse"
            [attr.data-bs-target]="'#collapse' + r.id "
            aria-expanded="true"
            [attr.aria-controls]="'collapse' + r.id"
          >
            <span style="margin-right: 10px;">{{ r.code }}</span>
            {{ r.title }}
          </button>
        </h2>

        <div
          [id]="'collapse' + r.id"
          [class]="'accordion-collapse collapse' + (first ? ' show' : '')"
          [attr.aria-labelledby]="'heading' + r.id"
          data-bs-parent="#training-recommendations"
        >
          <div class="accordion-body">
            <div class="d-flex">
              <div class="level-scored">
                  <h4>Level scored: {{ r.current_level }}</h4>
                  <div>{{ r.current_answer }}</div>
              </div>
              <div class="next-level">
                  <h4>Next level: {{ r.next_level }}</h4>
                  <div>{{ r.next_answer }}</div>
              </div>
            </div>
            <div class="progress-recommendations mt-3"
              *ngIf="r.recommendations.length > 0"
            >
              <h4>Recommendations to move to {{ r.next_level }}</h4>
              <ul>
                <li *ngFor="let text of r.recommendations">
                  {{ text }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div>

      <div class="improvement-actions mt-5" *ngIf="node.actions?.length > 0">
        <h3>Improvement actions</h3>
        <ul>
            <li *ngFor="let action of node.actions">{{ action.text }}
                <span *ngIf="action.completed"> [Completed on {{ action.completed | date:'mediumDate' }}]</span>
            </li>
        </ul>
      </div>

      <div class="mt-5">
        <h4>Add an improvement action for this area:</h4>
        <form id="add-action{{i}}">
            <textarea (input)="setActionText(node.path, $event)" rows="3" id="action-text{{i}}" class="w-100 my-3"></textarea>
            <button (click)="saveAction(node.path)" class="add-action-button">
            Add
            </button>
        </form>
      </div>

      <hr />
    </div>
  </div>

  <div class="nav-controls d-flex justify-content-between w-100 my-5">
    <button class="back-button" [routerLink]="'/'">Back to Dashboard</button>
    <button class="share-report" (click)="downloadReport($event)">
      Download PDF
    </button>
  </div>
</ng-template>
