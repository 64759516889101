import { Component, Output, EventEmitter } from '@angular/core';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AssessmentComponent } from './components/assessment/assessment.component';
import { ResultsComponent } from './components/results/results.component';
import { AssessmentsService } from './assessments.service';
import { AuthService } from '@auth0/auth0-angular';
import { Router } from '@angular/router';

import { Subscription } from 'rxjs';
import { AdminComponent } from './pages/admin/admin.component';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css'],
})
export class AppComponent {
  title = 'Maturity Assessment Portal';

  sidebar: object = {};
  context: object = { 'staff_user': false };

  subscription: Subscription;
  assessment: AssessmentComponent;
  recommendations: ResultsComponent;
  
  constructor(
    public auth: AuthService,
    private assessments: AssessmentsService
  ) {}

  ngOnInit() {
    this.assessments.sessionContext$.subscribe(
      (context) => (this.context = context)
    );
    this.assessments.viewContext$.subscribe(
      (sidebar) => (this.sidebar = sidebar)
    );
  }
}
