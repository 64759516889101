import { Component, EventEmitter, Output } from '@angular/core';
import { AssessmentsService } from 'src/app/assessments.service';

@Component({
  selector: 'app-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.css']
})
export class DashboardComponent {
  module_id: number;
  heading: string = "Dashboard";
  profile: object;
  context: object;

  overview: object;
  recent: object;
  actions: object;
  recommendations: object;

  chart_data: object;
  chart_series: object[];
  chart_selected: string[];

  audience: string | undefined;
  hasApiError = false;
  isLoading = true;

  @Output() sidebar:EventEmitter<object> = new EventEmitter<object>();

  constructor(private assessments: AssessmentsService) {}

  ngOnInit() {
    const module_id = localStorage.getItem('module');
    this.module_id = module_id ? Number(module_id) : 1;
    this.updateDashboard();
  }
  
  updateDashboard() {
    this.assessments.dashboard$(this.module_id).subscribe({
      next: (res) => {
        this.hasApiError = false;
        this.isLoading = false;

        this.chart_data = res['chart']['data'];
        this.chart_series = res['chart']['series'];
        this.chart_selected = res['chart']['selected'];

        this.overview = res['overview'];
        this.recent = res['assessments'];
        this.actions = res['actions'];

        // TODO: check does this not come back in the context from the API?
        this.module_id = res['_context']['current_module'];

        this.assessments.addSessionContext(res['_context']);
        this.assessments.setSidebar(res['sidebar']);
      },
      error: () => {
        this.hasApiError = true;
        this.isLoading = false;
      },
    });
  }

  setModule(module_id: number) {
    this.module_id = module_id;
    localStorage.setItem('module', module_id.toString());
    this.updateDashboard();
  }

  chartSeries(change) {
    const assessment_id: string = change.assessment_id;
    switch(change.action) {
      case 'add':
        if(!this.chart_selected.includes(assessment_id)) {
          this.assessments.assessmentChart$(assessment_id).subscribe({
            next: (res) => {
              this.hasApiError = false;
              this.chart_data[assessment_id] = res['axes'];
              this.chart_data = Object.assign({}, this.chart_data); // force change detection
              this.chart_selected.push(assessment_id);
              this.chart_selected = this.chart_selected.map((x) => x); // force change detection
            },
            error: () => this.hasApiError = true,
          });
        }
        else {
          this.chart_selected.push(assessment_id);
          this.chart_selected = this.chart_selected.map((x) => x); // force change detection
        }
        break;

      case 'remove':
        this.chart_selected = this.chart_selected.filter(
          function(value){ return value != assessment_id }
        );
        break;
    }
  }
}
