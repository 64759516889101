<h2>Assessor Details</h2>

<div class="assessment-fields mb-3" *ngIf="profile">
    <div class="field-label">Assessor Name</div>
    <div class="field-data">{{ profile['name'] }}</div>
    <div class="field-label">Function</div>
    <div class="field-data mb-3">
        {{ profile['https://assessments.oliverwight-eame.com/function'] }}
    </div>
</div>

<div class="assessment-fields new-assessment mb-5" *ngIf="data['modules']">
    <h3 class="mb-3">New Assessment</h3>
    <div class="tiered-colors">
        <select class="field-data mb-3" (change)="setModule($event.target.value)">
            <option *ngFor="let module of data['modules']" [value]="module.id" [selected]="module.id == context['current_module']">
                {{ module.label }}
            </option>
        </select>
        <select class="field-data mb-3" [hidden]="!category" (change)="setCategory($event.target.value)">
            <option *ngFor="let c of categories" [value]="c.id">
                {{ c.label }}
            </option>
        </select>
        <select class="field-data mb-3" [hidden]="!organisation || !filtered_orgs" (change)="setOrganisation($event.target.value)">
            <option *ngFor="let o of filtered_orgs" [value]="o.id">
                {{ o.label }}
            </option>
        </select>
        <select class="field-data mb-3" [hidden]="!market" (change)="setMarket($event.target.value)">
            <option *ngFor="let m of markets" [value]="m.id">
                {{ m.label }}
            </option>
        </select>
    </div>
    <button class="action-button mt-5" (click)="startAssessment()">
        Start Assessment
    </button>
</div>
