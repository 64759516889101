<div class="container mt-5" *ngIf="auth.user$ | async as user">
  <div
    class="row align-items-center profile-header mb-5 text-center text-md-left"
  >
    <div class="col-md-2">
      <img
        [src]="user.picture"
        class="rounded-circle img-fluid profile-picture mb-3 mb-md-0"
      />
    </div>
    <div class="col-md">
      <h2>{{ user.name }}</h2>
      <p class="lead text-muted">{{ user.email }}</p>
    </div>
  </div>

  <div class="row" *ngIf="profileJson">
    <pre class="rounded"><code class="json" [highlight]="profileJson"></code></pre>
  </div>
</div>
<pre>
  {{ context | json }}
</pre>