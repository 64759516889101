<div class="progress-box" *ngIf="context && context['assessment']">
    <h4 class="field-label">Assessment Progress</h4>
    <div class="assessment-progress">
        <div class="progress-meter-container">
            <canvas id="progress-meter">
                {{ progressMeter }}
            </canvas>
            <div class="progress-percent">
                {{ (progress['answer_count'] / progress['question_count']) * 100  | number: '1.0-1' }}%
            </div>
        </div>
        <div class="field-data progress-text">
            {{ context['assessment']['module'] }}<br />
            {{ progress['answer_count'] }} / {{ progress['question_count'] }}
        </div>
    </div>
    <div>
        <button class="mt-2" (click)="setComplete()"
            *ngIf="progress['answer_count'] == progress['question_count']"
        >
            Submit Assessment
        </button>
    </div>
</div>
<ul *ngIf="context && context['assessment']">
    <li *ngFor="let ancestor of data['area']['ancestors']; let i = index"
        class="navbox" [ngClass]="i == 0 ? 'module' : 'ancestor'"
    >
        <div class="nav-label d-flex">
            <div class="code">{{ ancestor.code | numberOrBlank }}</div>
            <a [routerLink]="['/assessment', context['assessment']['id']]"
                [queryParams]="{path: ancestor.path + '/' + ancestor.id}"
            >
                {{ ancestor.label }}
            </a>
        </div>
    </li>

    <li *ngFor="let sibling of data['area']['siblings']"
        class="navbox" [ngClass]="data['area']['ancestors'].length == 0 ? 'module' : data['area']['children'].length ? 'sibling' : 'child'"
    >
        <div class="nav-label d-flex">
            <div class="code">{{ sibling.code | numberOrBlank }}</div>
            <a [routerLink]="['/assessment', context['assessment']['id']]"
                [queryParams]="{path: sibling.path + '/' + sibling.id}"
            >{{ sibling.label }}</a>
        </div>

        <ul *ngIf="data['area']['id'] == sibling['id']" class="sibling-children">
            <li *ngFor="let node of data['area']['children']" class="navbox child">
                <div class="nav-label d-flex">
                    <div class="code">{{ node.code | numberOrBlank }}</div>
                    <a [routerLink]="['/assessment', context['assessment']['id']]"
                        [queryParams]="{path: node.path + '/' + node.id}"
                    >
                        {{ node.label }}
                    </a>
                </div>
            </li>
            <li *ngFor="let question of data['area']['questions']; let i = index"
                class="navbox child question"
                [ngClass]="{'unanswered': !context['answered'].includes(question['id']), 'selected': i == context['q_index']}"
            >
                <div class="nav-label d-flex" (click)="navMoveToQuestion(i)">
                    <div class="completed-tick"></div>
                    <div class="code">{{ question.code }}</div>
                    <a>{{ question.label }}</a>
                </div>
            </li>
        </ul>
    </li>
</ul>
