<div class="container-fluid px-0">
  <div *ngIf="hasApiError" class="alert alert-danger" role="alert">
    An error occured when trying to call the assessments endpoint.
  </div>
</div>

<app-loading *ngIf="isLoading; else loaded" />
<ng-template #loaded>
  <div class="container-fluid px-0 mb-5" *ngIf="!hasApiError">
    <app-assessment-overview
      [data]="overview"
      [module_id]="module_id"
      [chart_data]="chart_data"
      [chart_selected]="chart_selected"
      [chart_series]="chart_series"
      (module)="setModule($event)"
      (series)="chartSeries($event)"
    />
    <app-recent-assessments [data]="recent" />
    <app-improvement-actions [data]="actions" />
  </div>
</ng-template>
