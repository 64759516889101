<div class="container" *ngIf="auth.isLoading$ | async; else loaded">
  <app-loading></app-loading>
</div>

<ng-template #loaded>
  <div class="d-flex flex-column h-100">
    <app-header></app-header>

    <div class="container-fluid p-0 flex-grow-1 flex-shrink-1">
      <div class="d-flex justify-content-between h-100">
        <div class="main-column mb-5">
          <app-nav-bar></app-nav-bar>
          <router-outlet></router-outlet>
        </div>
        <div class="side-column" *ngIf="sidebar">
          <app-sidebar></app-sidebar>
        </div>
      </div>
    </div>

    <app-footer></app-footer>
  </div>
</ng-template>