<p>
</p>

<form [formGroup]="regionForm" (ngSubmit)="onSubmit()" class="container">
    <div class="row py-0" *ngIf="selectedRegion < 0">
        <p>Create a new Region:</p>
    </div>
    <div class="row py-0" *ngIf="selectedRegion >= 0">
        <p>
            You are changing the existing Region called
            '{{ regions[selectedRegion]['label'] }}':
        </p>
    </div>
    <div class="row">
        <div class="col-6">
            <label for="label">Label:</label>
            <input type="text" id="label" formControlName="label">
        </div>
    </div>
    <div class="row">
        <div class="col-12">
            <button type="submit" *ngIf="selectedRegion < 0" [disabled]="regionForm.invalid">
                Create
            </button>
            <button type="submit" *ngIf="selectedRegion >= 0" [disabled]="regionForm.invalid">
                Save
            </button>
        </div>
    </div>
</form>