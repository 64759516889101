import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { DashboardComponent } from './components/dashboard/dashboard.component';
import { AssessmentComponent } from './components/assessment/assessment.component';
import { ReviewComponent } from './components/review/review.component';
import { ResultsComponent } from './components/results/results.component';
import { ProfileComponent } from './pages/profile/profile.component';
import { ErrorComponent } from './pages/error/error.component';
import { LoginComponent } from './pages/login/login.component';
import { AdminComponent } from './pages/admin/admin.component';
import { AuthGuard } from '@auth0/auth0-angular';
import { MaintenanceModeComponent } from './pages/maintenance-mode/maintenance-mode.component';

const routes: Routes = [
  {
    path: 'admin',
    component: AdminComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'profile',
    component: ProfileComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'login',
    component: LoginComponent,
  },
  {
    path: 'maintenance',
    component: MaintenanceModeComponent,
  },
  {
    path: 'error',
    component: ErrorComponent,
  },
  {
    path: 'assessment/create-or-resume/:nav_id/:area_id',
    component: AssessmentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'assessment/create-or-resume/:area_id',
    component: AssessmentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'assessment/:assessment_id',
    component: AssessmentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'resume/:assessment_id',
    component: AssessmentComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'review/:assessment_id',
    component: ReviewComponent,
    canActivate: [AuthGuard],
  },
  {
    path: 'results/:assessment_id',
    component: ResultsComponent,
    canActivate: [AuthGuard],
  },
  {
    path: '',
    component: DashboardComponent,
    pathMatch: 'full',
    canActivate: [AuthGuard],
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule],
})
export class AppRoutingModule {}
