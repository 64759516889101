import { Component, Input } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AssessmentsService } from 'src/app/assessments.service';

@Component({
  selector: 'app-edit-category',
  templateUrl: './edit-category.component.html',
  styleUrls: ['./edit-category.component.css']
})
export class EditCategoryComponent {
  categoryForm: FormGroup;

  @Input() categories: object[] = [];
  @Input() selectedCategory: number = -1;

  constructor(private assessments: AssessmentsService) {}

  ngOnInit() {
    this.categoryForm = new FormGroup({
      label: new FormControl('', Validators.required),
    });
    this.updateCategoryForm();
  }

  ngOnChanges(changes) {
    this.updateCategoryForm();
  }

  updateCategoryForm() {
    if(this.categoryForm) {
      if(this.selectedCategory >= 0) {
        var category = this.categories[this.selectedCategory];
        this.categoryForm.controls.label.setValue(category['label']);
      }
      else {
        this.categoryForm.controls.label.setValue('');
      }
    }
  }
  
  onSubmit() {
    const id = this.selectedCategory >= 0 ? this.categories[this.selectedCategory]['id'] : 0;
    this.assessments.updateCategory$(id, {
        'label': this.categoryForm.controls.label.value,
    }).subscribe({
      next: (res) => {
        if('id' in res) {
          if(this.selectedCategory >= 0) {
            this.categories[this.selectedCategory] = res;
          }
          else {
            this.categories.push(res);
          }
          // this.categoryForm.reset();
        }
      },
    });
  }
}
