import { Component } from '@angular/core';
import { FormGroup, FormControl, Validators } from '@angular/forms';
import { AssessmentsService } from 'src/app/assessments.service';

@Component({
  selector: 'app-invite-user',
  templateUrl: './invite-user.component.html',
  styleUrls: ['./invite-user.component.css']
})
export class InviteUserComponent {
  inviteForm: FormGroup;

  constructor(private assessments: AssessmentsService) {}

  ngOnInit() {
    this.inviteForm = new FormGroup({
      name: new FormControl('', Validators.required),
      email: new FormControl('', [Validators.required, Validators.email]),
      role: new FormControl('', Validators.required)
    });
  }

  onSubmit() {
    this.assessments.inviteUser$(
      this.inviteForm.controls.email.value,
      this.inviteForm.controls.name.value,
      this.inviteForm.controls.role.value,
    ).subscribe({
      next: (res) => {
        if('id' in res) {
          this.assessments.pushAction('add_invite', res);
          this.inviteForm.reset();
        }
        else {
          console.log(res);
        };
      },
    })
  }
}
