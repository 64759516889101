import { Component, Output, EventEmitter } from '@angular/core';
import { AssessmentsService } from '../../assessments.service';

@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.css']
})
export class SidebarComponent {

  constructor(
    private assessments: AssessmentsService
  ) {}

  @Output() action: EventEmitter<object> = new EventEmitter<object>();

  sidebar: object = {};
  context: object = {'staff_user': false};

  ngOnInit() {
    this.assessments.sessionContext$.subscribe({
      next: (context) => {
        this.context = context;
      }}
    );
    this.assessments.viewContext$.subscribe({
      next: (sidebar) => {
        this.sidebar = sidebar;
      }}
    );
  }
}
